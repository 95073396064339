<template>
  <div class="splash-cnt">
    <div class="splash-box">
      <span class="img-box">
        <img src="../assets/images/logo.svg" />
      </span>
    </div>
    <!-- <loadingIcon v-if="loading" /> -->
    <Toaster :dataValue="toasterData" />
  </div>
</template>
<script>
import Toaster from '../components/Toaster';
import { SessionHelper, LANG } from '../services/Session';
// import constants from "@/utils/constants";
export default {
  name: 'Splash',
  components: {
    Toaster,
  },
  data() {
    return {
      toasterData: {},
    };
  },
  mounted() {
    // SessionHelper.setCookie('JWT-TOKEN', 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI4MzQ5MzUxNzEyIiwiWC1BdXRoLUlEIjoiYjNmMDQzNjZiNzJhMDk5MTNjNDMyYjBjMmExMjY2ZDkiLCJpYXQiOjE1OTg0NDk2MTMsImV4cCI6MTU5ODQ1MDUxM30.tB34qNhZGifyJhVDS77d_SnFp10ZoLbBVXQ7KTWVscs')
    this.$i18n.locale = SessionHelper.getCookie(LANG) || 'en';
    if(history.length <= 2){
      this.$router.push("/dashboard");
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/splash";
</style>
